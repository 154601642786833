import { useState } from 'react';
import { errorMessages, successMessages } from '../../../../components/messages/commonMessages';
import { useConveniosService } from '../../../../service/convenios';
import { useComisionService } from '../../../../service/comision';
import { useConvenioRecaudadores } from '../useConvenioRecaudadores';

export const useFormulario = (onSave, onCancel, convenio, render, oficinas ) => {
    const [objConvenio, setObjConvenio] = useState(convenio !== '' ? convenio[0] : '');
    const [tipoFacturador, setTipoFacturador] = useState('');
    const [dataMonedas, setDataMonedas] = useState([]);
    const [dataTiposConvenio, setDataTiposconvenio] = useState([]);
    const [dataComisiones, setDataComisiones] = useState([]);
    const [dataOficinasFilter, setDataOficinasFilter] = useState([]);
    const { handleConvenioSearch } = useConvenioRecaudadores();
      const periodicidades = [
        {id:"1", dsc:"Mensual"},
        {id:"2", dsc:"Semestral 2"},
        {id:"3", dsc:"Semestral 3"},
        {id:"4", dsc:"Semestral 4"}

    ];
    const { post, put, getTiposMoneda, getTiposConvenio } = useConveniosService();
    const { get } = useComisionService();

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
      
        // Dividir el nombre en partes utilizando '.' como delimitador
        const nameParts = name.split('.');
        
        // Manejar el caso en el que solo hay una parte en el nombre
        if (nameParts.length === 1) {
          // Si solo hay una parte en el nombre, actualizar directamente la propiedad en el estado
          if (type === 'checkbox') {
            // Si es un checkbox, manejar el cambio de acuerdo a la propiedad 'checked'
            setObjConvenio(prevState => ({
              ...prevState,
              [name]: checked
            }));
          } else {
            // Para otros tipos de inputs, como textos o selects, usar el valor normal
            setObjConvenio(prevState => ({
              ...prevState,
              [name]: value
            }));
          }
        } else {
          // Si hay múltiples partes en el nombre, navegar a través del estado para actualizar la propiedad correcta
          setObjConvenio(prevState => ({
            ...prevState,
            [nameParts[0]]: {
              ...prevState[nameParts[0]],
              [nameParts[1]]: value
            }
          }));
        }
      };

    const handleOnchangeFacturador =  async (value) => {
        setTipoFacturador(value);
        const oficinasFiltradas = oficinas.filter(oficina => oficina.facturador.idFacturador == value);
        setDataOficinasFilter(oficinasFiltradas);
    }

    const getMonedas = async() =>{
        getTiposMoneda().then((data) => {
            setDataMonedas(data);
        })
    }

    const getTipConvenios = async() =>{
        getTiposConvenio().then((data) => {
            setDataTiposconvenio(data);
        })
    }


    const getComisiones = async() =>{
        get().then((data) => {
            setDataComisiones(data);
        })
    }
    const addConvenios = async (values) => {
        post(values);
    }
    const updateConvenios = async (idParametro, values) => {
        put(idParametro, values);
    }


    const isValidForm = () =>{
        return objConvenio.idRecaudador && objConvenio.idOficina && 
        objConvenio.codInterno && objConvenio.dscConvenio && 
        objConvenio.tipMoneda.idTipMoneda && objConvenio.comision.idComision && 
        objConvenio.ctaBancaria && objConvenio.idTipConvenio && objConvenio.tipoPeriodicidad && 
        objConvenio.codInternoProveedor && objConvenio.indFctUnica
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isValidForm) {
            if (render === 'Add') {
                objConvenio.indActivo = 1;
                await addConvenios(objConvenio);

            } else {
                let idConvenio = objConvenio.idConvenio
                if (idConvenio !== undefined) {
                    await updateConvenios(idConvenio, objConvenio);
                    await handleConvenioSearch(objConvenio.idRecaudador, objConvenio.idOficina);
                }
            }
            onSave();
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };
    return(
        {objConvenio, tipoFacturador, handleInputChange, handleOnchangeFacturador, dataMonedas, getMonedas,
             dataTiposConvenio, getTipConvenios, dataComisiones, getComisiones, 
             periodicidades, dataOficinasFilter, handleSubmit}
    );
}