import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useOficinaService } from '../../../service/oficina';
import { useState } from 'react';
import { successMessages } from '../../messages/commonMessages';
import { useAgenciaService } from '../../../service/agencia';
import { useConveniosService } from '../../../service/convenios';

export const useConvenioRecaudadores = () => {
    const [selectedRow, setSelectedRow] = useState();
    const [tipoFacturador, setTipoFacturador] = useState('');
    const [recaudador, setRecaudador] = useState('');
    const [oficina, setOficina] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [dataOficinas, setDataOficinas] = useState([]);
    const [dataOficinasFilter, setDataOficinasFilter] = useState([]);
    const [dataFacturadores, setDataFacturadores] = useState([]);
    const [dataRecaudadores, setDataRecaudadores] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getAll, getFacturador } = useOficinaService();
    const { getRecaudadores } = useAgenciaService();
    const { getByOficinaAndRecaudador, patch } = useConveniosService();

    const getOficinas = async(idFacturador) =>{
        try {
            setLoading(true);
            const result = await getAll(idFacturador);
            setDataOficinas(result);
        } catch (error) {
            console.error('Error fetching oficinas:', error);
        } finally {
            setLoading(false);
        }
    }

    const getFacturadores = async() =>{
        try {
            setLoading(true);
            const result = await getFacturador();
            setDataFacturadores(result);
        } catch (error) {
            console.error('Error fetching facturadores:', error);
        } finally {
            setLoading(false);
        }
    }

    const getRecaudadoresList = async() =>{
        try {
            setLoading(true);
            const result = await getRecaudadores();
            setDataRecaudadores(result);
        } catch (error) {
            console.error('Error fetching recaudadores:', error);
        } finally {
            setLoading(false);
        }
    }

    const columnas = [
        { field: 'dscConvenio', headerName: 'Descripción', flex: 1, },
        { 
            field: 'tipMoneda', 
            headerName: 'Moneda', 
            flex: 1, 
            renderCell: (params) => (
              <span>{params.value.dscMoneda}</span>
            )
          },
          { 
            field: 'comision', 
            headerName: 'Tipo de Comisión', 
            flex: 1, 
            renderCell: (params) => (
              <span>{params.value.dscComision}</span>
            )
          },
        { field: 'ctaBancaria', headerName: 'Cuenta Bancaria', flex: 1, },
        { field: 'codInterno', headerName: 'Código Interno', flex: 1, },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleOnchangeFacturador =  async (value) => {
        setTipoFacturador(value);
        const oficinasFiltradas = dataOficinas.filter(oficina => oficina.facturador.idFacturador == value);
        setDataOficinasFilter(oficinasFiltradas);
    }

    const handleConvenioSearch = async (idRecaudador, idOficina) => {
        try {
            if (idRecaudador && idOficina) {
                setLoading(true);
                const newdata = await getByOficinaAndRecaudador(idRecaudador, idOficina);
                setData(newdata);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleOficina = (value) => {
        setOficina(value)
    };

    const handleRecaudador = (value) => {
        setRecaudador(value)
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleConfirmationDelete = async() => {
        let idConvenio = selectedRow[0].idConvenio;
        patch(idConvenio);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRows);
        console.log(selectedRows[0].descripcion);
    };
    return(
        {selectedRow, tipoFacturador, recaudador, oficina, isDialogOpen, columnas,
             isAdd, isEdit, isView, isDelete, handleAdd, handleCloseDialog,
              handleSave, handleCancel, handleRowSelectionChange, handleConfirmationDelete, handleOnchangeFacturador, handleConvenioSearch, handleOficina, handleRecaudador,
               data, loading, dataOficinas, dataOficinasFilter, getFacturadores, dataFacturadores, getRecaudadoresList, dataRecaudadores,
             getOficinas}
    );
}