import { useEffect } from "react";
import { useFormulario } from "./useFormulario";
import Tooltip from '@mui/material/Tooltip';
const Formulario = ({ onSave, onCancel, convenio, render, recaudadores, facturadores, oficinas, facturadorFiltro }) => {
    const { objConvenio, tipoFacturador, handleInputChange, handleOnchangeFacturador, dataMonedas, getMonedas,
        dataTiposConvenio, getTipConvenios, dataComisiones, getComisiones,
        periodicidades, dataOficinasFilter, handleSubmit } =
        useFormulario(onSave, onCancel, convenio, render, oficinas)


    useEffect(() => {
        getMonedas();
        getTipConvenios();
        getComisiones();
    }, []);
    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Recaudador:
                    </label>
                    <Tooltip title="Recaudador">
                        <select className='select-form' name="idRecaudador" value={objConvenio.idRecaudador} onChange={(e) => handleInputChange(e)} disabled={render === 'View' || render === 'Edit' ? true : null}>
                            <option value="">Seleccione</option>
                            {recaudadores && recaudadores.map((option, index) => (
                                <option key={index} value={option.idRecaudador}>{option.nomRecaudador}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Facturador:
                    </label>
                    <Tooltip title="Facturador">
                        <select className='select-form' value={tipoFacturador} onChange={(e) =>
                            handleOnchangeFacturador(e.target.value)} disabled={render === 'View' || render === 'Edit' ? true : null}>
                            {render !== 'View' && render !== 'Edit' && (
                                <>
                                    <option value="">Seleccione</option>
                                    {facturadores && facturadores.map((option, index) => (
                                        <option key={index} value={option.idFacturador}>{option.nomFacturadr}</option>
                                    ))}
                                </>
                            )}

                            {/* Si estamos en modo de edición o vista, mostramos solo la opción seleccionada */}
                            {(render === 'View' || render === 'Edit') && (
                                <>
                                {facturadores && facturadorFiltro && facturadores
                                        .filter(option => option.idFacturador == facturadorFiltro)
                                        .map((option, index) => (
                                            <option key={index} value={option.idFacturador}>{option.nomFacturadr}</option>
                                        ))
                                    }
                                </>
                            )}

                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Oficina:
                    </label>
                    <Tooltip title="Oficina">
                        <select className='select-form' name="idOficina" value={objConvenio.idOficina} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' || render === 'Edit' ? true : null}>

                            {/* Agregamos una opción predeterminada dependiendo del valor de `render` */}
                            {render !== 'View' && render !== 'Edit' && (
                                <>
                                    <option value="">Seleccione</option>
                                    {dataOficinasFilter && dataOficinasFilter.map((option, index) => (
                                        // Validamos si la opción es diferente a la seleccionada en el objeto convenio
                                        (option.idOficina !== objConvenio.idOficina) && (
                                            <option key={index} value={option.idOficina}>{option.nomOficina}</option>
                                        )
                                    ))}
                                </>
                            )}

                            {/* Si estamos en modo de edición o vista, mostramos solo la opción seleccionada */}
                            {(render === 'View' || render === 'Edit') && (
                                <>
                                    {oficinas && oficinas
                                        .filter(option => option.idOficina === objConvenio.idOficina)
                                        .map((option, index) => (
                                            <option key={index} value={option.idOficina}>{option.nomOficina}</option>
                                        ))
                                    }
                                </>
                            )}

                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Interno:
                    </label>
                    <Tooltip title="Código Interno">
                        <input className='input-form' name="codInterno" type="text" maxLength={50} placeholder='Código Interno'
                            value={objConvenio.codInterno} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' name="dscConvenio" maxLength={300} type="text"
                            placeholder='Descripción' value={objConvenio.dscConvenio} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Moneda:
                    </label>
                    <Tooltip title="Moneda">
                        <select className='select-form' name="tipMoneda.idTipMoneda" value={objConvenio?.tipMoneda?.idTipMoneda ?? ''} onChange={(e) => handleInputChange(e)} disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione</option>
                            {dataMonedas && dataMonedas.map((option, index) => (
                                <option key={index} value={option.idTipMoneda}>{option.dscMoneda}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo de Comisión:
                    </label>
                    <Tooltip title="Tipo de Comisión">
                        <select className='select-form' name="comision.idComision" value={objConvenio?.comision?.idComision ?? ''} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} >
                            <option value="">Seleccione</option>
                            {dataComisiones && dataComisiones.map((option, index) => (
                                <option key={index} value={option.idComision}>{option.dscComision}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Cuenta Bancaria:
                    </label>
                    <Tooltip title="Cuenta Bancaria">
                        <input className='input-form' maxLength={100} type="text" name="ctaBancaria" placeholder='Cuenta Bancaria' value={objConvenio.ctaBancaria}
                            onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo de Convenio:
                    </label>
                    <Tooltip title="Tipo de Convenio">
                        <select className='select-form' name="idTipConvenio" value={objConvenio.idTipConvenio} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione</option>
                            {dataTiposConvenio && dataTiposConvenio.map((option, index) => (
                                <option key={index} value={option.idTipConvenio}>{option.dscTipConvenio}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Tipo de Periodicidad:
                    </label>
                    <Tooltip title="Tipo de Periodicidad">
                        <select className='select-form' name="tipoPeriodicidad" value={objConvenio.tipoPeriodicidad} onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione</option>
                            {periodicidades && periodicidades.map((option, index) => (
                                <option key={index} value={option.id}>{option.dsc}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label style={{ width: "90%", marginLeft: "30px" }}>
                        Código Interno Proveedor:
                    </label>
                    <Tooltip title="Código Interno proveedor">
                        <input className='input-form' maxLength={3}
                            value={objConvenio.codInternoProveedor} type="text" name="codInternoProveedor" onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label >
                        Indicador Factura Única:
                    </label>
                    <Tooltip title="Indicador Factura Única">
                        <input style={{ width: "10%" }} className='input-form' maxLength={3} name="indFctUnica"
                            value={objConvenio.indFctUnica} type="checkbox" onChange={(e) => handleInputChange(e)}
                            disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario }