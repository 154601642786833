import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";

export const useOficinaService = () => {
    const { serverData } = useConectividadApi();
    const session = useSelector(state => state.session);

    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de oficinas
   */
    const get = async (idFacturador) => {
        try {
            const options = {
                method: 'GET',
                url:  `/oficinas/facturador/${idFacturador}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    const getAll = async () => {
        try {
            const options = {
                method: 'GET',
                url:  '/oficinas',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
* @methodApi post.
* @description  Método encargado de crear oficinas
*/
    const post = async (values) => {
        try {
            const options = {
                method: 'POST',
                url: '/oficinas',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
              };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }
/**
* @methodApi v.
* @description  Método encargado de actualizar oficinas
*/
    const put = async (oficinaId, values) => {
        try {
            const options = {
                method: 'PUT',
                url: `/oficinas/${oficinaId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
              };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }

    /**
* @methodApi v.
* @description  Método encargado de eliminar oficinas
*/
const patch = async (oficinaId) => {
    try {
        const options = {
            method: 'PATCH',
            url: `/oficinas/${oficinaId}`,
            headers: {
                Authorization: `Bearer ${session.credentials.accessToken}`
            },
          };
        try {
            await serverData(options);
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    } catch (error) {
        let { data } = error;
        alert(data.msg);
    }
}

/**
* @methodApi v.
* @description  Método encargado de obtener los facturadores 
*/
const getFacturador = async () => {
    try {
        const options = {
            method: 'GET',
            url:  '/oficinas/facturadores',
            headers: {
                Authorization: `Bearer ${session.credentials.accessToken}`
            }
        };
        return await serverData(options).then(response => {
            let data = response;
            if (data) {
                return data;
            }
        });
    } catch (error) {
        let { data } = error;
        alert(data.msg);
    }
}

    return {
        //Api Parametros
        get,
        getAll,
        post,
        put,
        patch,
        getFacturador,
    }

}