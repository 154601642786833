import React, { useEffect } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import '../../../App.css'
import { successMessages, confirmationMessages } from '../../messages/commonMessages.jsx';
import Tooltip from '@mui/material/Tooltip';
import { AlertDialog } from '../../messages/AlertDialog.jsx'
import { Formulario } from './Formulario/Formulario.jsx';
import { useCanal } from './useCanal.js';


const MantenimientoCanal = () => {
    const { handleRowSelectionChange, getCanales, data, handleCloseDialog, columnas,
        isDialogOpen, isEdit, isView, isAdd, handleAdd, handleCancel,
        isDelete, selectedRow,
        handleSave } = useCanal();
    
    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };
    useEffect(() => {
        getCanales();
    }, []);
    return (
        <div className='content-wrapper'>
            <div className="wrapper-card">
                <h1>
                    Mantenimiento de Canales
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Lista de Canales
                            </div>
                            <div className="content">
                                <DataTable
                                    data={data && Array.isArray(data) ? data.map(row => ({ id: row.idCanal, ...row })) : []}
                                    columns={columnas}
                                    onRowSelectionChange={handleRowSelectionChange} >
                                    {data === null && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Cargando datos...
                                        </div>
                                    )}
                                </DataTable>
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Canal'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} canal={selectedRow} render={'Add'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Canal'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} canal={selectedRow} render={'Edit'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Canal'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} canal={selectedRow} render={'View'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                        question={'Eliminar Canal'}
                                        message={<> {confirmationMessages.DELETE_CONFIRMATION_CANAL} <br/> <div style={{marginLeft: '20%'}} > <b>Canal:</b> {selectedRow[0].nomCanal} </div> </>}
                                        isOpen={isDialogOpen}
                                        onClose={handleCancel}
                                        onAccept={handleCancel}
                                        onCancel={handleCancel}
                                    />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoCanal };