import { useConvenioRecaudadores } from "./useConvenioRecaudadores";
import React, { useEffect } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { successMessages, confirmationMessages } from '../../messages/commonMessages.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import '../../../App.css'
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import Tooltip from '@mui/material/Tooltip';
import { Formulario } from './Formulario/Formulario.jsx';
import Spinner from '../../spinner/LoadingSpinner.js';

const MantenimientoConvenioRecaudadores = () => {
    const { selectedRow, tipoFacturador, recaudador, oficina, isDialogOpen, columnas,
        isAdd, isEdit, isView, isDelete, handleAdd, handleCloseDialog,
        handleSave, handleCancel, handleRowSelectionChange, handleConfirmationDelete, handleOnchangeFacturador, handleConvenioSearch, handleOficina, handleRecaudador,
        data, loading, dataOficinas, dataOficinasFilter, getFacturadores, dataFacturadores, getRecaudadoresList, dataRecaudadores,
        getOficinas } = useConvenioRecaudadores();

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };
    useEffect(() => {
        getFacturadores();
        getRecaudadoresList();
        getOficinas();
    }, []);
    return (
        <div className='content-wrapper'>
            {loading && <Spinner />}
            <div className="wrapper-card">
                <h1>
                    Mantenimiento de Convenios Facturadores
                </h1>
                <div className='form-container'>
                    <div className="content">

                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                            <div className="fila-form ">
                                    <div className="columna-form">
                                        <label>
                                            Recaudador:
                                        </label>
                                        <Tooltip title="Recaudador">
                                            <select id="select-recaudador" style={{width:'40%'}} className='select-form' value={recaudador} onChange={(e) => 
                                                handleRecaudador(e.target.value)}>
                                                <option value="">Seleccione el Recaudador</option>
                                                {dataRecaudadores && dataRecaudadores.map((option, index) => (
                                                    <option key={index} value={option.idRecaudador}>{option.nomRecaudador}</option>
                                                ))}
                                            </select>
                                        </Tooltip>
                                    </div>
                            </div>
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Facturador:
                                        </label>
                                        <Tooltip title="Facturador">
                                            <select id="select-facturador" className='select-form' value={tipoFacturador} onChange={(e) => handleOnchangeFacturador(e.target.value)}>
                                            <option value="">Seleccione el Facturador</option>
                                                {dataFacturadores && dataFacturadores.map((option, index) => (
                                                    <option key={index} value={option.idFacturador}>{option.nomFacturadr}</option>
                                                ))}
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="columna-form">
                                        <label>
                                            Oficina:
                                        </label>
                                        <Tooltip title="Facturador">
                                            <select id="select-oficina" className='select-form' value={oficina} onChange={(e) => 
                                                handleOficina(e.target.value)}>
                                            <option value="">Seleccione la Oficina</option>
                                                {dataOficinasFilter && dataOficinasFilter.map((option, index) => (
                                                    <option key={index} value={option.idOficina}>{option.nomOficina}</option>
                                                ))}
                                            </select>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="fila-form">
                                    <div className="button-filter" style={{textAlign:"right"}}>
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={() => handleConvenioSearch(recaudador, oficina)}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                    </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Convenios Facturadores
                            </div>
                            <div className="content">
                                <DataTable
                                    data={data && Array.isArray(data) ? data.map(row => ({ id: row.idConvenio, ...row })) : []}
                                    columns={columnas}
                                    onRowSelectionChange={handleRowSelectionChange} >
                                    {data === null && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Cargando datos...
                                        </div>
                                    )}
                                </DataTable>
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} convenio={selectedRow} 
                                            render={'Add'} recaudadores={dataRecaudadores} facturadores={dataFacturadores} oficinas={dataOficinas} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} convenio={selectedRow} 
                                            render={'Edit'}  recaudadores={dataRecaudadores} facturadores={dataFacturadores} oficinas={dataOficinas} 
                                            facturadorFiltro={tipoFacturador}/>}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} convenio={selectedRow} 
                                            render={'View'} recaudadores={dataRecaudadores} facturadores={dataFacturadores} oficinas={dataOficinas} 
                                            facturadorFiltro={tipoFacturador} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Convenio Facturador"}
                                            message={confirmationMessages.DELETE_CONFIRMATION_CONVENIO}
                                            isOpen={isDialogOpen}
                                            onClose={handleCancel}
                                            onAccept={handleConfirmationDelete}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoConvenioRecaudadores };