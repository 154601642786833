import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";

export const useCanalesService = () => {
    const { serverData } = useConectividadApi();
    const session = useSelector(state => state.session);

    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de canales
   */
    const get = async () => { 
        
        try {
            const options = {
                method: 'GET',
                url: '/canales',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
        
    }
    /**
* @methodApi post.
* @description  Método encargado de crear canales
*/
    const post = async (values) => {

        
        try {
            const options = {
                method: 'POST',
                url: '/canales',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
              };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }
/**
* @methodApi v.
* @description  Método encargado de actualizar canales
*/
    const put = async (idCanal, values) => {
         
        try {
            const options = {
                method: 'PUT',
                url: `/canales/${idCanal}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
              };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }

    }

/**
* @methodApi v.
* @description  Método encargado de actualizar canales
*/
    const patch = async (idCanal) => {
         
        try {
            const options = {
                method: 'PATCH',
                url: `/canales/${idCanal}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
              };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }

    }
    return {
        //Api Canales
        get,
        post,
        put,
        patch,
    }
}